// @mui material components

// @mui material components
import { CircularProgress, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import { environment } from "@/lib";
import SoftBox from "@components/SoftBox";
import SoftButton from "@components/SoftButton";
import SoftTypography from "@components/SoftTypography";
import { useCart } from "@core/hooks";
import lodash from "@core/lodash";
import { apiPost } from "@core/utils/api/api";
import { toMoment } from "@core/utils/time";
import { useAuthStore } from "@store/AuthStore";
import { MessageStore } from "@store/MessageStore";
import { useOrderPendingStore, useOrderStore } from "@store/OrderStore";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useAccount,
  useContract,
  useNetwork,
  useProvider,
  useSigner,
} from "wagmi";
import Web3 from "web3";

const { chainName, currentChainId, hexChainId } = environment.chainData;

function CheckoutSide({
  btnText,
  onClick,
  variant,
  cartItems = [],
  paymentGateway = "wallet",
  onSelect,
  cardId = "new",
}) {
  const dispatch = useDispatch();

  const [isGiftCard, setIsGiftCard] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSetGiftCard = () => setIsGiftCard(!isGiftCard);

  const navigate = useNavigate();
  const orderStore = useOrderStore();
  const orderPendingStore = useOrderPendingStore();
  const authStore = useAuthStore();
  const user = authStore.user();
  const userId = authStore.authId;

  const orders = orderStore.items;
  const pendingOrders = orderPendingStore.items;

  const paymentSuccessOrders = lodash.filter(
    pendingOrders,
    (p) => p.status === "success"
  );

  const paymentMethods = user?.paymentMethods || [];

  const mintedOrders = orders?.filter((o) => (o?.type || "mint") === "mint");
  const mintedPlanIds = mintedOrders?.map((o) => o?.planId);

  const { isConnected, address, connector } = useAccount();
  const { chain } = useNetwork();

  const cart = useCart();
  const totalQuantity = cartItems?.length || 0;
  const totalPrice = cartItems?.reduce((acc, item) => acc + item.price, 0);

  const { data: signer } = useSigner({});
  const provider = useProvider({
    chainId: environment.chainData.currentChainId,
  });

  const planContract = useContract({
    address: environment.contract.address,
    abi: environment.contract.abi,
    signerOrProvider: !address ? provider : signer,
  });

  const speContract = useContract({
    address: environment.speContract.address,
    abi: environment.speContract.abi,
    signerOrProvider: !address ? provider : signer,
  });

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (paymentSuccessOrders.length > 0) {
      const successOrder = lodash.first(paymentSuccessOrders);

      if (successOrder.status === "success") {
        processSuccessOrder(successOrder);
      }
    }
  }, [paymentSuccessOrders?.length]);

  const processSuccessOrder = async (successOrder) => {
    const orderItems = successOrder.items;

    const totalQuantity = orderItems?.length || 0;
    const totalPrice = orderItems?.reduce((acc, item) => acc + item.price, 0);

    const orderData = successOrder?.orderData || {};

    orderItems.forEach(async (item, index) => {
      await orderStore.createItem({
        paymentHash: "",
        tokenHash: "",
        payment: successOrder?.payment || {},
        userId: [userId],
        card: item,
        productId: item.id,
        planId: item.id,
        reward: item.reward,
        quantity: 1,
        price: item.price,
        type: orderData?.type,
        walletAddress: "",
        giftCode: orderData?.giftCode || "",
        createdBy: userId,
        status: "active",
      });

      if (orderItems.length === index + 1) {
        setLoading(false);
        NotificationManager.success("Paiement Réussi", "Succès", 3000);
      }

      cart.removeItem(item.id);
    });

    const totalCards = !isGiftCard
      ? parseFloat(user?.totalCards || 0) + parseFloat(totalQuantity)
      : parseFloat(user?.totalCards || 0);

    await authStore.updateItem({
      ...user,
      totalSpending:
        parseFloat(user?.totalSpending || 0) + parseFloat(totalPrice),
      totalCards: totalCards,
    });

    await orderPendingStore.deleteItem(successOrder.id);

    await authStore.getItem(userId);
  };

  const onHandlePay = async (e) => {
    if (!isConnected) {
      dispatch(MessageStore.setOpenModal(true));
    } else {
      setLoading(true);

      const hasAnyMintedItem = mintedPlanIds?.some((id) =>
        cartItems?.some((item) => item.id === id)
      );

      if (hasAnyMintedItem && !isGiftCard) {
        setLoading(false);
        NotificationManager.warning(
          "Vous avez déjà acheté certains des articles de votre panier",
          "Déjà Frappé",
          3000
        );
        return;
      }

      if (chain.id !== currentChainId) {
        try {
          const switchToBscMainnet = await window.ethereum
            .request({
              method: "wallet_switchEthereumChain",
              params: [
                {
                  chainId: hexChainId, // BSC mainnet chain ID
                },
              ],
            })
            .then(() => true)
            .catch(() => false);

          if (!switchToBscMainnet) {
            NotificationManager.warning(
              `Veuillez passer au réseau ${chainName} pour continuer`,
              "Erreur:",
              6000
            );
            return;
          }
        } catch (error) {
          console.log("Error", error);
        }
      }

      try {
        const totalAmount = Web3.utils.toWei(totalPrice.toString(), "ether");

        const txSPE = await speContract.approve(
          environment.contract.address,
          totalAmount.toString()
        );
        const txSPEResult = await txSPE.wait();

        const paySuccess = await planContract.sendTokens(
          totalAmount.toString()
        );

        const paySuccessResult = await paySuccess.wait();

        await purchaseCards(
          {
            paymentHash: String(paySuccessResult?.transactionHash),
            tokenHash: String(txSPEResult?.transactionHash),
          },
          {}
        );

        navigate("/plans");
      } catch (error) {
        setLoading(false);

        let errorMessage = error?.message || error;

        if (errorMessage.includes("rejected transaction")) {
          errorMessage = `Transaction rejetée. Veuillez réessayer`;
        } else if (errorMessage.includes("execution reverted")) {
          errorMessage = `Transaction rejetée. Veuillez réessayer`;
        }

        NotificationManager.warning(errorMessage, "Veuillez réessayer", 3000);
      }
    }
  };

  const purchaseCards = async (orderData, userData = {}) => {
    const giftCode = String(toMoment().unix());

    cartItems.forEach(async (item, index) => {
      await orderStore.createItem({
        ...orderData,
        userId: [userId],
        card: item,
        productId: item.id,
        planId: item.id,
        reward: item.reward,
        quantity: 1,
        price: item.price,
        type: isGiftCard ? "gift" : "mint",
        walletAddress: "",
        giftCode: isGiftCard ? giftCode : "",
        createdBy: userId,
        status: "active",
      });

      if (cartItems.length === index + 1) {
        setLoading(false);
        NotificationManager.success("Paiement Réussi", "Succès", 3000);
      }
    });

    cart.clearCart();

    const totalCards = !isGiftCard
      ? parseFloat(user?.totalCards || 0) + parseFloat(totalQuantity)
      : parseFloat(user?.totalCards || 0);

    await authStore.updateItem({
      ...user,
      ...userData,
      totalSpending:
        parseFloat(user?.totalSpending || 0) + parseFloat(totalPrice),
      totalCards: totalCards,
    });

    await authStore.getItem(userId);
  };

  const onCardPayMooney = async (data) => {
    const hasAnyMintedItem = mintedPlanIds?.some((id) =>
      cartItems?.some((item) => item.id === id)
    );

    if (hasAnyMintedItem && !isGiftCard) {
      NotificationManager.warning(
        "Vous avez déjà acheté certains des articles de votre panier",
        "Déjà Frappé",
        3000
      );
      return;
    }

    setLoading(true);

    const planNames = cartItems.map((plan) => plan.title).join(", ");
    const amount = cartItems.reduce(
      (acc, plan) => acc + parseFloat(plan.price),
      0
    );

    const apiResponse = await apiPost(
      `https://us-central1-recjhasce-cameroon.cloudfunctions.net/createMooneyPaymentLink`,
      {
        email: user?.email,
        name: user?.name,
        customerId: user?.stripeId || "",
        itemName: planNames,
        ids: cartItems.map((plan) => plan.id),
        amount: amount,
        mode: "production",
      },
      "POST"
    );

    if (apiResponse?.sessionId && apiResponse?.payment_url) {
      localStorage.setItem("payment_info_data", {
        ...apiResponse,
      });

      const giftCode = String(toMoment().unix());

      await orderPendingStore.createItem({
        itemRef: apiResponse.itemRef,
        amount: amount,
        email: user?.email,
        name: user?.name,
        status: "pending",
        items: cartItems,
        userId: [user?.id],
        createdBy: user?.id,
        user: user,
        orderData: {
          type: isGiftCard ? "gift" : "mint",
          walletAddress: "",
          giftCode: isGiftCard ? giftCode : "",
          createdBy: userId,
        },
      });

      window.open(apiResponse.payment_url, "_self");

      return;
    } else {
      NotificationManager.error(
        "Something went wrong.",
        "Please try again",
        3000
      );
      setLoading(false);
    }
  };

  const onStripePay = async (data) => {
    if (elements == null) {
      return;
    }

    const hasAnyMintedItem = mintedPlanIds?.some((id) =>
      cartItems?.some((item) => item.id === id)
    );

    if (hasAnyMintedItem && !isGiftCard) {
      NotificationManager.warning(
        "Vous avez déjà acheté certains des articles de votre panier",
        "Déjà Frappé",
        3000
      );
      return;
    }

    setLoading(true);

    let currentCardId = cardId;

    let updatedPaymentMethods = paymentMethods;
    let stripeCustomerId = user?.stripeId || "";

    if (cardId == "new") {
      const { error: stripeTokenError, token: stripeCardToken } =
        await stripe.createToken(elements.getElement("card"));

      if (stripeTokenError?.message) {
        NotificationManager.error(
          stripeTokenError?.message || "Something went wrong.",
          "Please try again",
          3000
        );
        setLoading(false);
        return;
      }

      const apiResponse = await apiPost(
        `https://us-central1-recjhasce-cameroon.cloudfunctions.net/stripeCreateSource`,
        {
          sourceToken: stripeCardToken?.id,
          email: user?.email,
          name: user?.name,
          customerId: user?.stripeId || "",
        },
        "POST"
      );
      const paymentMethod = apiResponse?.card;
      if (paymentMethod?.id) {
        currentCardId = paymentMethod.id;
        stripeCustomerId = paymentMethod?.customer;

        updatedPaymentMethods = [
          ...paymentMethods,
          {
            id: paymentMethod.id,
            name: data?.name || user?.name,
            brand: paymentMethod.brand,
            last4: paymentMethod.last4,
            country: paymentMethod.country,
            expiryMonth: paymentMethod.exp_month,
            expiryYear: paymentMethod.exp_year,
            customer: paymentMethod.customer,
            type: "card",
          },
        ];
      } else {
        NotificationManager.error(
          "Something went wrong.",
          "Please try again",
          3000
        );
        setLoading(false);
        return;
      }
    }

    if (currentCardId) {
      const apiChargeResponse = await apiPost(
        `https://us-central1-recjhasce-cameroon.cloudfunctions.net/createStripeCharge`,
        {
          sourceId: currentCardId,
          customerId: stripeCustomerId,
          amount: totalPrice * 100,
        },
        "POST"
      );

      const charge = apiChargeResponse?.charge;

      if (charge?.id) {
        await purchaseCards(
          {
            paymentHash: "",
            tokenHash: "",
            stripeChargeId: charge?.id,
            stripeCustomerId: charge?.customer,
          },
          {
            paymentMethods: updatedPaymentMethods,
            stripeId: stripeCustomerId,
          }
        );

        navigate("/plans");
      }
      // NotificationManager.success(`Successfully Created`, "Success", 4000);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <Card
      sx={{
        height: "100%",
        paddingBottom: "15px",
      }}
    >
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        pt={2}
        px={2}
      >
        <Typography variant="h5">Total Du Panier</Typography>
      </SoftBox>
      <Divider />

      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={4}
        pt={2}
        px={2}
        sx={{ borderTop: "1px solid #eee" }}
      >
        <SoftTypography variant="h6" color="text" fontWeight="bold">
          Total
        </SoftTypography>
        <SoftTypography variant="h6" color="text" fontWeight="bold">
          ${totalPrice}
        </SoftTypography>
      </SoftBox>

      <SoftBox
        display="flex"
        alignItems="center"
        variant="gradient"
        color="info"
        sx={{ marginLeft: "20px", marginTop: "20px" }}
      >
        <Checkbox
          checked={isGiftCard}
          onChange={handleSetGiftCard}
          sx={{
            border: "1px solid #0842ac!important",
          }}
        />

        <SoftTypography
          component="a"
          href="#"
          variant="button"
          color="info"
          fontWeight="bold"
          textGradient
        >
          Acheter En Carte Cadeau
        </SoftTypography>
      </SoftBox>

      {paymentGateway !== "card" && (
        <SoftBox my={4} mx={2} mb={1} minWidth="3rem">
          <SoftButton
            onClick={() => onHandlePay()}
            // component={Link}
            // to="/authentication/payment"
            variant="gradient"
            fullWidth
            type="submit"
            color="info"
            disabled={
              totalQuantity <= 0 || loading || user?.status !== "approved"
            }
          >
            <div
              className="d-flex gap-10 align-items-center justify-content-center"
              style={{ gap: 10 }}
            >
              {loading && (
                <CircularProgress
                  color="secondary"
                  size={18}
                  className="mr-5"
                />
              )}

              {isConnected ? "Payez Maintenant" : "Connecter Le Portefeuille"}
            </div>
          </SoftButton>
        </SoftBox>
      )}
      {paymentGateway === "card" && (
        <SoftBox my={4} mx={2} mb={1} minWidth="3rem">
          <SoftButton
            onClick={() => onCardPayMooney()}
            variant="gradient"
            fullWidth
            type="button"
            color="info"
            disabled={
              totalQuantity <= 0 || loading || user?.status !== "approved"
            }
          >
            <div
              className="d-flex gap-10 align-items-center justify-content-center"
              style={{ gap: 10 }}
            >
              {loading && (
                <CircularProgress
                  color="secondary"
                  size={18}
                  className="mr-5"
                />
              )}
              Payez Maintenant
            </div>
          </SoftButton>
        </SoftBox>
      )}
      {/* CART BOTTON PROCEED TO CHECKOUT WITH CONNECT WALLET */}
      {false && (
        <>
          <SoftBox my={4} mx={2} mb={1} minWidth="3rem">
            {onSelect === "wallet" && (
              <SoftButton
                onClick={() => onHandlePay()}
                variant="gradient"
                fullWidth
                type="submit"
                color={"info"}
                disabled={
                  totalQuantity <= 0 || loading || user?.status !== "approved"
                }
              >
                <div
                  className="d-flex gap-10 align-items-center justify-content-center"
                  style={{ gap: 10 }}
                >
                  {loading && (
                    <CircularProgress
                      color="secondary"
                      size={18}
                      className="mr-5"
                    />
                  )}
                  {isConnected
                    ? "Payez Maintenant"
                    : "Connecter Le Portefeuille"}
                </div>
              </SoftButton>
            )}

            {/* CART BOTTON PROCEED TO CARD PAYMENT */}
            {onSelect === "card" && (
              <SoftButton
                // onClick={onClick}
                variant="gradient"
                fullWidth
                type="submit"
                color={"info"}
                disabled={
                  totalQuantity <= 0 || loading || user?.status !== "approved"
                }
              >
                <div
                  className="d-flex gap-10 align-items-center justify-content-center"
                  style={{ gap: 10 }}
                >
                  {loading && (
                    <CircularProgress
                      color="secondary"
                      size={18}
                      className="mr-5"
                    />
                  )}
                  {/* {isConnected ? 'Payez Maintenant' : 'Connecter Le Portefeuille'} */}
                  Payer par carte
                </div>
              </SoftButton>
            )}

            {/* CART BOTTON PROCEED TO CHECKOUT */}

            {!onSelect ? (
              <SoftButton
                onClick={onClick}
                variant="gradient"
                fullWidth
                type="submit"
                color={"info"}
                disabled={
                  totalQuantity <= 0 || loading || user?.status !== "approved"
                }
              >
                <div
                  className="d-flex gap-10 align-items-center justify-content-center"
                  style={{ gap: 10 }}
                >
                  {loading && (
                    <CircularProgress
                      color="secondary"
                      size={18}
                      className="mr-5"
                    />
                  )}
                  {/* {isConnected ? 'Payez Maintenant' : 'Connecter Le Portefeuille'} */}
                  Sélectionnez le mode de paiement
                </div>
              </SoftButton>
            ) : null}
          </SoftBox>
        </>
      )}
    </Card>
  );
}

export default CheckoutSide;
