import { useAuthStore } from "@store/AuthStore";
import { BaseStore } from "@store/BaseStore";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";

class OrderPendingStore extends BaseStore {
  userId = null;

  constructor(firestore, selector, userId) {
    super("pendingOrders", firestore, selector);
    this.userId = userId;
  }

  fetchItems = async () => {
    return this.getItemsByQuery([["userId", "array-contains", this.userId]]);
  };

  fetchItemsByUserId = async (userId) => {
    return this.getItemsByQuery([["userId", "==", userId]]);
  };
}

export const useOrderPendingStore = () => {
  const userId = useAuthStore().authId;
  return new OrderPendingStore(useFirestore(), useSelector, userId);
};
