// @mui material components
import Grid from "@mui/material/Grid";
import image from "../../assets/images/giftBox.jpg";

// Soft UI Dashboard React components
import SoftBox from "@components/SoftBox";

// Soft UI Dashboard React components

// Soft UI Dashboard React examples
import DashboardLayout from "@examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "@examples/Navbars/DashboardNavbar";

// Billing page components
import SoftButton from "@components/SoftButton";
import { FormInput } from "@core/forms";
import { toMoment } from "@core/utils/time";
import { Card, CircularProgress, Typography } from "@mui/material";
import { useAuthStore } from "@store/AuthStore";
import { useOrderStore } from "@store/OrderStore";
import _ from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import GiftCardsList from "./components/GiftCodesList";

function Giftcode() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const orderStore = useOrderStore();
  const authStore = useAuthStore();
  const user = authStore.user();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const onSubmit = async (data) => {
    const giftCards = orderStore.flatResponse(
      await orderStore.fetchItemsByGiftCode(data.giftCode)
    );
    const filteredGiftCards = giftCards?.filter(
      (g) => (g?.status || "active") === "active" && g?.createdBy != user.id
    );

    if (filteredGiftCards?.length == 0) {
      NotificationManager.error("Invalid Gift Code", "Error", 2000);
      setError("giftCode", {
        type: "manual",
        message: "Invalid gift code",
      });
    } else {
      setLoading(true);

      filteredGiftCards?.forEach(async (giftCard, index) => {
        await orderStore.updateItem({
          ...giftCard,
          userId: _.concat(giftCard.userId || [], user.id),
          status: "redeemed",
          redeemedAt: toMoment().unix(),
        });

        if (index == filteredGiftCards.length - 1) {
          setLoading(false);
          NotificationManager.success(
            "Gift code redeemed successfully",
            "",
            2000
          );
          navigate("/dashboard");
        }
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        sx={{
          marginTop: "30px",
          background: " linear-gradient(90deg, #aaa 100%, #555 0%)",
        }}
      >
        <SoftBox>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <SoftBox
                component="form"
                role="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{ padding: "40px 40px" }}
              >
                <Typography
                  // variant='h2'
                  py={1}
                  sx={{
                    fontWeight: "800",
                    fontSize: { sm: "30px", md: "40px" },
                    color: "#fff",
                  }}
                >
                  Utiliser Un Code Cadeau
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", marginBottom: "30px", color: "#fff" }}
                >
                  Veuillez noter que votre licence commerciale n'a pas encore
                  expiré.
                </Typography>
                <FormInput
                  type="text"
                  control={register("giftCode", {
                    required: "Gift code is required",
                  })}
                  className="form-control"
                  placeholder="Code Cadeau"
                  error={errors.giftCode}
                />

                <SoftBox mt={2}>
                  <SoftButton
                    variant="gradient"
                    fullWidth
                    color="info"
                    type="submit"
                    disabled={loading}
                  >
                    <div
                      className="d-flex gap-10 align-items-center justify-content-center"
                      style={{ gap: 10 }}
                    >
                      {loading && (
                        <CircularProgress
                          color="secondary"
                          size={18}
                          className="mr-5"
                        />
                      )}
                      Utiliser Le Code
                    </div>
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </Grid>
            <Grid
              item
              sm={12}
              lg={6}
              sx={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: " center",

                // transform: 'skewX(10deg)',
              }}
            >
              <SoftBox
                sx={{
                  backgroundImage: `url(${image})`,
                  backgroundSize: "cover",
                  backgroundPosition: " center",

                  // transform: 'skewX(10deg)',
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>
      </Card>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <GiftCardsList />
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Giftcode;
