import MyButton from "@components/MyButton/MyButton";
import SoftBox from "@components/SoftBox";
import SoftTypography from "@components/SoftTypography";
import { toMoment } from "@core/utils/time";
import { Groups2 } from "@mui/icons-material";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import { useAuthStore } from "@store/AuthStore";
import { useOrderStore } from "@store/OrderStore";
import _ from "lodash";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const GiftCardsList = () => {
  const navigate = useNavigate();
  const orderStore = useOrderStore();
  const authStore = useAuthStore();
  const user = authStore.user();
  const orders = orderStore.items;

  const redeemedGiftCards = orders?.filter(
    (o) =>
      (o?.type || "mint") === "gift" &&
      // o.createdBy !== user.id &&
      o.status == "redeemed"
  );

  const ownerGiftCards = orders?.filter(
    (o) => (o?.type || "mint") === "gift" && o.createdBy === user.id
  );

  const ownerGiftCodes = _.groupBy(ownerGiftCards, "giftCode");
  const redeemedGiftCodes = _.groupBy(redeemedGiftCards, "giftCode");
  const giftCards = { ...ownerGiftCodes, ...redeemedGiftCodes };

  const sortedGiftCodes = _.orderBy(
    Object.keys(giftCards).map((key) => ({
      createdAt: key,
      cards: giftCards[key],
    })),
    ["createdAt"],
    ["desc"]
  );

  const giftCodesList = Object.keys(giftCards);

  useEffect(() => {
    orderStore.fetchItems();
  }, []);

  return (
    <SoftBox mb={1} mt={1}>
      <TableContainer component={Paper}>
        <SoftBox py={1} px={2}>
          <SoftTypography
            // variant='h3'
            my={2}
            sx={{
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              fontSize: { sm: "20px", md: "30px" },
              color: "#0842ac",
            }}
          >
            <Groups2
              sx={{
                width: "44px",
                height: "44px",
                padding: "10px",
                fontSize: "10px",
                color: "#555",
                margin: "10px",
                display: { xs: "none", sm: "inline-block" },
                borderRadius: "100%",
                backgroundColor: "#eee",
                overflow: "visible",
              }}
            />
            Codes Cadeaux Achetés Récemment
          </SoftTypography>

          {sortedGiftCodes.length === 0 && (
            <SoftTypography
              variant="h5"
              sx={{
                textAlign: "center",
                paddingTop: "30px",
                paddingBottom: "30px",
              }}
            >
              You haven't purchased an gift codes yet.
            </SoftTypography>
          )}
          {sortedGiftCodes.length > 0 && (
            <>
              <Table
                aria-label="simple table"
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: "10px",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    Code
                  </TableCell>
                  <TableCell sx={{ fontWeight: "600", fontSize: "18px" }}>
                    Date D'inscription
                  </TableCell>
                  <TableCell sx={{ fontWeight: "600", fontSize: "18px" }}>
                    Actif / Racheté
                  </TableCell>
                </TableRow>
                <TableBody>
                  {sortedGiftCodes.map((item, index) => {
                    const key = item.createdAt;
                    const cards = item.cards;
                    const giftCard = _.first(cards);
                    // const cards = giftCards[key];
                    // const giftCard = _.first(cards);

                    return (
                      <TableRow key={1}>
                        <TableCell> {key} </TableCell>
                        <TableCell>
                          {toMoment(giftCard.createdAt).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>
                          {giftCard?.status == "redeemed" ? (
                            <MyButton bgColor="#bf0000">Racheté</MyButton>
                          ) : (
                            <MyButton bgColor="#0842ac"> Actif</MyButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <SoftBox my={2} pt={1}></SoftBox>
            </>
          )}
        </SoftBox>
      </TableContainer>
    </SoftBox>
  );
};

export default GiftCardsList;
