// @mui material components
import Grid from "@mui/material/Grid";
import COIN from "../../assets/images/popol-coin.png";

// Soft UI Dashboard React components
import SoftBox from "@components/SoftBox";

// Soft UI Dashboard React examples
import MiniStatisticsCard from "@examples/Cards/StatisticsCards/MiniStatisticsCard";
import Footer from "@examples/Footer";
import DashboardLayout from "@examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "@examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React base styles
import typography from "@assets/theme/base/typography";

// Dashboard layout components

// Data
import { toMoment } from "@core/utils/time";
import reportsBarChartData from "@layouts/dashboard/data/reportsBarChartData";
import KcyVerify from "@layouts/notifications/KcyVerify";
import { useAuthStore } from "@store/AuthStore";
import { useOrderStore } from "@store/OrderStore";
import _ from "lodash";
import { useEffect } from "react";
import PurchasedCard from "./components/PurchasedCard";

const currentDate = new Date().toLocaleDateString();

function Dashboard() {
  const { size } = typography;
  const { chart, items } = reportsBarChartData;

  const authStore = useAuthStore();
  const orderStore = useOrderStore();
  const user = authStore.user();
  const orders = orderStore.items;

  const dob = user?.kyc?.dob || "";
  const city = user?.kyc?.city || "";

  const cardPrefix = `${_.toUpper(
    String(user?.name || "").substring(0, 2)
  )}${toMoment(dob).format("MMYY")}${_.toUpper(String(city).substring(0, 3))}`;

  const ownerCards = orders?.filter((o) => o.createdBy === user.id);

  const mintedCards = orders?.filter((o) => (o?.type || "mint") === "mint");

  const giftCards = orders?.filter(
    (o) =>
      (o?.type || "mint") === "gift" &&
      o.createdBy !== user.id &&
      o.status == "redeemed"
  );
  const ownerGiftCards = orders?.filter(
    (o) => (o?.type || "mint") === "gift" && o.createdBy === user.id
  );
  const totalTokens = ownerCards.reduce((acc, o) => acc + o?.reward || 0, 0);

  const totalGiftCards = ownerGiftCards.length;

  useEffect(() => {
    orderStore.fetchItems();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <KcyVerify />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={4}>
              <MiniStatisticsCard
                title={{ text: "Total Des Jetons" }}
                count={totalTokens || 0}
                // percentage={{ color: 'success', text: '+55%' }}
                icon={{ color: "info" }}
                image={COIN}
              />
            </Grid>

            <Grid item xs={12} sm={6} xl={4}>
              <MiniStatisticsCard
                title={{ text: "Achat De Cartes Cadeaux" }}
                count={totalGiftCards}
                // percentage={{ color: 'error', text: '-2%' }}
                icon={{ color: "info", component: "emoji_events" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
              <MiniStatisticsCard
                title={{ text: "Dépenses Totales" }}
                count={`$${user?.totalSpending || 0}`}
                // percentage={{ color: 'success', text: '+5%' }}
                icon={{
                  color: "info",
                  component: "shopping_cart",
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>

        <SoftBox>
          <Grid container spacing={3} display="flex" p={0}>
            {mintedCards.map((card, index) => (
              <Grid item xs={12} sm={6} lg={4} key={card.id}>
                <PurchasedCard
                  title={user.name}
                  cardName={card.card.title}
                  buyDate={toMoment(card.createdAt).format("DD/MM/YYYY")}
                  price={`$${card.price}`}
                  // code="EKYXLPM00098981"
                  code={`${cardPrefix}${toMoment(card.createdAt).format(
                    "YYYYMMDD"
                  )}`}
                  planId={card.card?.id || card?.planId}
                />
              </Grid>
            ))}
            {giftCards.map((card, index) => (
              <Grid item xs={12} sm={6} lg={4} key={card.id}>
                <PurchasedCard
                  title={user.name}
                  cardName={""}
                  buyDate={toMoment(card.createdAt).format("DD/MM/YYYY")}
                  price={`$${card.price}`}
                  code={`${cardPrefix}${toMoment(card.createdAt).format(
                    "YYYYMMDD"
                  )}`}
                  planId={card.card?.id || card?.planId}
                />
              </Grid>
            ))}
          </Grid>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
