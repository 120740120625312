import { environment } from "@/lib";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { MessageStore } from "@store/MessageStore";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import coinbaseLogo from "../../assets/images/coinbase.svg";
import metamaskLogo from "../../assets/images/metamask.svg";
import walletConnectLogo from "../../assets/images/walletconnect.svg";
import "./WalletModal.css";

const {
  blockExplorerUrls,
  chainName,
  currentChainId,
  hexChainId,
  name,
  rpcUrls,
  symbol,
} = environment.chainData;

export const WalletModal = () => {
  const dispatch = useDispatch();
  const openModal = useSelector(MessageStore.selectOpenModal);

  const { isConnected } = useAccount();
  const { disconnectAsync } = useDisconnect();
  const { connectAsync } = useConnect();

  const handleClose = () => {
    dispatch(MessageStore.setOpenModal(false));
  };

  const handleAuth = async (provider) => {
    if (isConnected) {
      await disconnectAsync();
    }
    try {
      let connector;
      if (provider === "metamask") {
        connector = new InjectedConnector({
          chains: environment.chains,
        });
      } else if (provider === "coinbase") {
        connector = new CoinbaseWalletConnector({
          chains: environment.chains,
          options: {
            appName: "RECJHASCE",
          },
        });
      } else if (provider === "walletconnect") {
        connector = new WalletConnectConnector({
          chains: environment.chains,
          options: {
            showQrModal: true,
            projectId: "a480c2eef21d206c772d7fc377dc5886",
          },
          // https://cloud.walletconnect.com/app/project?uuid=30bffc78-3514-4d26-9e6f-f1aadc301c85 (loggedin with 0x...256c MM)
          // infuraId: "fb5ee8ed33d4329a20cee18eae646aa6",
        });
        setTimeout(() => {
          dispatch(MessageStore.setOpenModal(false));
        }, 1500);
      }
      const { account, chain } = await connectAsync({
        connector,
      });

      if (chain.id !== currentChainId) {
        console.log("chain", chain, currentChainId);
        // Check if the chain ID is not $currentChainId
        try {
          const isNetworkExist = await window.ethereum
            .request({
              method: "wallet_addEthereumChain",
              params: environment.chains,
            })
            .then(() => true)
            .catch(() => false);

          if (!isNetworkExist) {
            // If the user rejected adding the BSC mainnet network, show an error message and return
            NotificationManager.warning(
              `Veuillez passer au réseau ${chainName} pour continuer`,
              "Erreur:",
              6000
            );
            // return;
          }
        } catch (error) {
          console.log("Erreur", error);
        }
      }

      if (chain.id !== currentChainId) {
        console.log("chain", chain, currentChainId);
        // Check if the chain ID is not BSC mainnet
        // If the current network is not BSC mainnet, show a confirmation dialog to switch to BSC mainnet
        try {
          const switchToBscMainnet = await window.ethereum
            .request({
              method: "wallet_switchEthereumChain",
              params: [
                {
                  chainId: hexChainId, // BSC mainnet chain ID
                },
              ],
            })
            .then(() => true)
            .catch(() => false);

          if (!switchToBscMainnet) {
            // If the user rejected switching to BSC mainnet, show an error message and return
            NotificationManager.warning(
              `Please switch to the ${chainName} network to continue`,
              "Error:",
              6000
            );
            return;
          }
        } catch (error) {
          console.log("Error", error);
        }
      }
      dispatch(MessageStore.setOpenModal(false));
      dispatch(MessageStore.setWallet(account));
    } catch (e) {
      console.log("Log", e);
    }
  };

  // useEffect(() => {
  //   const supportChainList = JSON.parse(import.meta.env.VITE_CHAIN_ID);
  //   setChainList(supportChainList);
  // }, []);

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      PaperProps={{
        style: {
          backgroundColor: "#fff",
          backdropFilter: "blur(4px)",
          maxWidth: "380px",
          width: "100%",
          border: "1px solid #fff4",
          borderRadius: "10px",
        },
      }}
      transitionDuration={0}
    >
      <DialogTitle sx={{ backgroundColor: "#fff" }}>
        <h3 className="modal-heading"> Select wallet to connect </h3>
      </DialogTitle>
      <DialogContent>
        <div className="modal-body">
          <div onClick={() => handleAuth("coinbase")} className="connect-box">
            <img src={coinbaseLogo} alt="" width={40} />
            <h2>Coin Base</h2>
          </div>
          <div
            onClick={() => handleAuth("walletconnect")}
            className="connect-box"
          >
            <img src={walletConnectLogo} alt="" width={40} />
            <h2>Wallet Connect</h2>
          </div>
          <div onClick={() => handleAuth("metamask")} className="connect-box">
            <img src={metamaskLogo} alt="" width={40} />
            <h2>Metamask</h2>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
