

import { useState } from 'react';

// react-router-dom components
import { Link, useNavigate } from 'react-router-dom';

// @mui material components

// Soft UI Dashboard React components
import SoftBox from '@components/SoftBox';
import SoftTypography from '@components/SoftTypography';

// Authentication layout components
import CoverLayout from '@layouts/authentication/components/CoverLayout';

// Images
import curved9 from '@assets/images/signIn.png';
import { PrimaryButton } from '@components/Button';
import { FormInput } from '@core/forms';
import { ValidatorService } from '@core/services';
import { Hash } from '@core/utils/hash';
import { ArrowForward, Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuthStore } from '@store/AuthStore';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const authStore = useAuthStore();

  const navigate = useNavigate();
  const [userCredential, setUserCredential] = useState({});

  const [rememberMe, setRememberMe] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  function handleCredential(e) {
    setUserCredential({
      ...userCredential,
      [e.target.name]: e.target.value,
    });
  }

  const signIn = async data => {
    // e.preventDefault();

    const user = await authStore.findByEmail(data.email);

    // navigate("/dashboard");
  };

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const ShowPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async data => {
    setLoading(true);
    const user = await authStore.findByEmail(data.email);

    if (!user?.id) {
      setError('email', {
        type: 'manual',
        message: 'Invalid email or password',
      });
      setLoading(false);
      return;
    }

    if (!user?.verified) {
      localStorage.setItem(
        'ninjaRegisterHook',
        JSON.stringify({
          email: data.email,
          password: data.password,
        })
      );
      navigate(
        `/authentication/otp?email=${data.email}&code=${Hash.uniqueId()}`
      );
      return;
    }

    try {
      await authStore.login(data.email, data.password);

      navigate('/Tableau-de-bord');

      setLoading(false);
    } catch (error) {
      setError('email', {
        type: 'manual',
        message: 'Invalid email or password',
      });
      setLoading(false);
    }
  };

  return (
    <>
      <CoverLayout
        title='Content De Te Revoir'
        description='Entrez votre email et votre mot de passe pour vous connecter'
        image={curved9}
      >
        <SoftBox component='form' role='form' onSubmit={handleSubmit(onSubmit)}>
          <SoftBox mb={2}>
            <FormInput
              type='email'
              control={register('email', {
                required: 'Email is required',
                validate: ValidatorService.email,
              })}
              className='form-control'
              placeholder='Email'
              label='Email'
              error={errors.email}
            />
          </SoftBox>
          <SoftBox mb={2} sx={{ position: 'relative' }}>
            <FormInput
              type={!showPassword ? 'password' : 'text'}
              control={register('password', {
                required: 'Password is required',
                minLength: 8,
              })}
              className='form-control'
              placeholder='Password'
              label='Password'
              labelIcon={'<Visibility />'}
              error={errors.password}
            />
            <div className='form-icon-pass'>
              {showPassword && (
                <Visibility
                  className='form-icon cursor-pointer'
                  onClick={ShowPasswordHandler}
                />
              )}
              {!showPassword && (
                <VisibilityOff
                  className='form-icon cursor-pointer'
                  onClick={ShowPasswordHandler}
                />
              )}
            </div>{' '}
          </SoftBox>

          <SoftBox display='flex' alignItems='center'>
            <SoftTypography
              component={Link}
              to='/authentication/forgot-password'
              variant='button'
              color='info'
              fontWeight='medium'
              textGradient
            >
              Mot De Passe Oublié <ArrowForward />
            </SoftTypography>
          </SoftBox>
          <SoftBox mt={4} mb={1}>
            <PrimaryButton
              loading={loading}
              variant='gradient'
              color='info'
              fullWidth
              type='submit'
            >
              se connecter
            </PrimaryButton>
          </SoftBox>
          <SoftBox mt={3} textAlign='center'>
            <SoftTypography variant='button' color='text' fontWeight='regular'>
            Vous n'avez pas de compte?
              <SoftTypography
                component={Link}
                to='/authentication/sign-up'
                variant='button'
                color='info'
                fontWeight='medium'
                textGradient
              >
                S'inscrire
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </CoverLayout>
    </>
  );
}

export default SignIn;
