// Soft UI Dashboard React layouts
import Dashboard from "@layouts/dashboard";
import Profile from "@layouts/profile";

// Soft UI Dashboard React icons

import Basket from "@examples/Icons/Basket";
import CustomerSupport from "@examples/Icons/CustomerSupport";
import Document from "@examples/Icons/Document";
import Shop from "@examples/Icons/Shop";
import Affiliate from "@layouts/affiliate";
import { VerifyOtpPage } from "@layouts/authentication/VerifyOtp";
import AddOTP from "@layouts/authentication/add-otp";
import NewPassword from "@layouts/authentication/new-password";
import ResetPassword from "@layouts/authentication/reset-password";
import Giftcode from "@layouts/giftcode";
import PackagePlans from "@layouts/package-plans";
import Checkout from "@layouts/package-plans/components/checkout";
import SelectPayment from "@layouts/package-plans/components/payment";
import Transactions from "@layouts/transactions";
import { CardGiftcard, ListAlt } from "@mui/icons-material";

const routes = [
  {
    type: "collapse",
    name: "Tableau De Bord",
    key: "Tableau de bord",
    route: "/Tableau-de-bord",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Des Plans",
    key: "des plans",
    route: "/des-plans",
    icon: <Basket size="12px" />,
    component: <PackagePlans />,
    noCollapse: true,
  },
  {
    key: "plans-vérifier",
    route: "/plans/verifier",
    icon: <Basket size="12px" />,
    component: <Checkout />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Affilier",
    key: "Affilier",
    route: "/affilier",
    icon: <Document size="12px" />,
    component: <Affiliate />,
    noCollapse: true,
  },
  // {
  //   type: 'collapse',
  //   name: 'Team Structure',
  //   key: 'team-structure',
  //   route: '/team-structure',
  //   icon: <Group size='12px' />,
  //   component: <TeamStructure />,
  //   noCollapse: true,
  // },

  {
    type: "collapse",
    name: "Code Cadeau",
    key: "code-cadeau",
    route: "/code-cadeau",
    icon: <CardGiftcard size="12px" />,
    component: <Giftcode />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Transactions",
    key: "transactions",
    route: "/transactions",
    icon: <ListAlt size="12px" />,
    component: <Transactions />,
    noCollapse: true,
  },
  { type: "title", title: "MON PROFIL", key: "account-pages" },
  {
    type: "collapse",
    name: "Profil",
    key: "profil",
    route: "/profil",
    icon: <CustomerSupport size="12px" />,
    component: <Profile />,
    noCollapse: true,
  },
  {
    key: "payment",
    route: "/authentication/payment",
    icon: <CardGiftcard size="12px" />,
    component: <SelectPayment />,
    noCollapse: true,
  },
  {
    key: "verify-otp",
    route: "/authentication/otp",
    icon: <CardGiftcard size="12px" />,
    component: <VerifyOtpPage />,
    noCollapse: true,
  },
  {
    key: "add-otp",
    route: "/authentication/add-otp",
    icon: <CardGiftcard size="12px" />,
    component: <AddOTP />,
    noCollapse: true,
  },
  {
    key: "reset-password",
    route: "/reset-password",
    icon: <CardGiftcard size="12px" />,
    component: <ResetPassword />,
    noCollapse: true,
  },
  {
    key: "new-password",
    route: "/authentication/new-password",
    icon: <CardGiftcard size="12px" />,
    component: <NewPassword />,
    noCollapse: true,
  },
];

export default routes;
