import SoftBox from "@components/SoftBox";
import SoftTypography from "@components/SoftTypography";
import { toMoment } from "@core/utils/time";
import DashboardLayout from "@examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "@examples/Navbars/DashboardNavbar";
import { ListAlt } from "@mui/icons-material";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import { useAuthStore } from "@store/AuthStore";
import { useOrderStore } from "@store/OrderStore";
import _ from "lodash";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Transactions = () => {
  const navigate = useNavigate();
  const orderStore = useOrderStore();
  const authStore = useAuthStore();
  const user = authStore.user();
  const orders = orderStore.items;

  const myOrders = _.groupBy(
    _.orderBy(
      orders?.filter((o) => o.createdBy === user.id),
      ["createdAt"],
      ["desc"]
    ),
    "createdAt"
  );

  const sortedOrders = _.orderBy(
    Object.keys(myOrders).map((key) => ({
      createdAt: key,
      orders: myOrders[key],
    })),
    ["createdAt"],
    ["desc"]
  );

  useEffect(() => {
    orderStore.fetchItems();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mb={1} mt={1}>
        <TableContainer component={Paper}>
          <SoftBox py={1} px={2}>
            <SoftTypography
              // variant='h3'
              my={2}
              sx={{
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                fontSize: { sm: "20px", md: "30px" },
                color: "#0842ac",
              }}
            >
              <ListAlt
                sx={{
                  width: "44px",
                  height: "44px",
                  padding: "10px",
                  fontSize: "10px",
                  color: "#555",
                  margin: "10px",
                  display: { xs: "none", sm: "inline-block" },
                  borderRadius: "100%",
                  backgroundColor: "#eee",
                  overflow: "visible",
                }}
              />
              Transactions
            </SoftTypography>

            {sortedOrders.length === 0 && (
              <SoftTypography
                variant="h5"
                sx={{
                  textAlign: "center",
                  paddingTop: "30px",
                  paddingBottom: "30px",
                }}
              >
                {/* You don't have any transactions yet. */}
                Vous n'avez encore aucune transaction.
              </SoftTypography>
            )}
            {sortedOrders.length > 0 && (
              <>
                <Table
                  aria-label="simple table"
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "10px!important",
                  }}
                >
                  <TableRow>
                    <TableCell sx={{ fontWeight: "600", fontSize: "18px" }}>
                      Date
                    </TableCell>
                    <TableCell sx={{ fontWeight: "600", fontSize: "18px" }}>
                      Package name
                    </TableCell>
                    <TableCell sx={{ fontWeight: "600", fontSize: "18px" }}>
                      Price
                    </TableCell>
                    <TableCell sx={{ fontWeight: "600", fontSize: "18px" }}>
                      Method
                    </TableCell>
                    <TableCell sx={{ fontWeight: "600", fontSize: "18px" }}>
                      Gift Card
                    </TableCell>
                    {/* <TableCell sx={{ fontWeight: "600", fontSize: "18px" }}>
                      Action
                    </TableCell> */}
                  </TableRow>
                  <TableBody>
                    {sortedOrders.map((item, index) => {
                      const order = _.first(item?.orders || []);
                      const packageOrders = myOrders[item?.createdAt];
                      const price = packageOrders.reduce(
                        (acc, o) => acc + o.price,
                        0
                      );
                      const names = packageOrders
                        .map((o) => o.card?.title)
                        .join(", ");
                      return (
                        <TableRow key={1}>
                          <TableCell>
                            {toMoment(order.createdAt).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell>{names}</TableCell>
                          <TableCell>${price}</TableCell>
                          <TableCell>
                            {order?.stripeChargeId ||
                            order?.payment?.ref_payment
                              ? "Credit/Debit card"
                              : "Web 3 Wallet"}
                          </TableCell>
                          <TableCell>
                            {order?.type === "gift" ? "Yes" : "No"}
                          </TableCell>
                          {/* <TableCell
                            sx={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ContentCopy />
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <SoftBox my={2} pt={1}></SoftBox>
              </>
            )}
          </SoftBox>
        </TableContainer>
      </SoftBox>
    </DashboardLayout>
  );
};

export default Transactions;
